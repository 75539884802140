import { Text, Modal, Title, Flex, Divider } from '@mantine/core';
import { useUserProfile } from 'fe/queries';
import { ProfileForm } from './ProfileForm';

export function ProfileGuard() {
  const { data: profile, isLoading: isLoadingProfile } = useUserProfile();
  return (
    <Modal
      data-testid="profile-guard-modal"
      data-loading={isLoadingProfile}
      title="Profile setup"
      role="dialog"
      opened={!isLoadingProfile && !profile?.profile}
      onClose={() => null}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size="lg"
      padding={40}
    >
      <Flex direction="column" gap="sm">
        <Title order={1}>Welcome to Hey Rafi 🎉</Title>
        <Text>
          Let's set up your profile so we can provide you with the best
          experience.
        </Text>
      </Flex>

      <Divider my="xl" />

      <ProfileForm />
    </Modal>
  );
}
