import {
  Button,
  Flex,
  NumberInput,
  SegmentedControl,
  TextInput,
  Text,
} from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { useCreateWorkflowStep, useUpdateWorkflowStep } from 'fe/queries';
import {
  OrganisationWorkflow,
  WorkflowStep,
  WorkflowStepType,
} from 'shared/db';
import { useState } from 'react';
import { WorkflowStepAttachmentForm } from './WorkflowStepAttachmentForm';
import { StyledEditor } from 'fe/ui/shared';

interface WorkflowStepFormProps {
  workflow: OrganisationWorkflow;
  workflowStep?: WorkflowStep;
  onSuccess: () => void;
  isFirstStep: boolean;
}

export function WorkflowStepForm({
  workflow,
  workflowStep,
  onSuccess,
  isFirstStep,
}: WorkflowStepFormProps) {
  const [content, setContent] = useState('');
  const { mutateAsync: create, isPending: isCreating } =
    useCreateWorkflowStep();
  const { mutateAsync: update, isPending: isUpdateLoading } =
    useUpdateWorkflowStep();

  const form = useForm({
    initialValues: {
      name: workflowStep?.name || '',
      subject: workflowStep?.subject || '',
      attachments: workflowStep?.attachments || [],
      wait_days: workflowStep?.wait_days || 0,
    },
    validate: {
      name: isNotEmpty(),
    },
  });

  const onSubmit = form.onSubmit(async (values) => {
    if (workflowStep) {
      await update({
        workflow_step_id: workflowStep.id,
        ...workflowStep,
        ...values,
        content,
      });
    } else {
      await create({
        workflow_id: workflow.id,
        type: WorkflowStepType.EMAIL,
        ...values,
        content,
      });
    }
    onSuccess();
  });

  return (
    <div>
      <form onSubmit={onSubmit}>
        <SegmentedControl
          data={[
            { value: WorkflowStepType.EMAIL, label: 'Email' },
            {
              value: WorkflowStepType.SMS,
              label: 'SMS (Coming soon)',
              disabled: true,
            },
          ]}
        />
        <TextInput
          mb="sm"
          withAsterisk
          label="Name"
          placeholder="Main workflow"
          {...form.getInputProps('name')}
        />
        <TextInput
          mb="sm"
          label="Subject (Optional)"
          placeholder="Cash offer"
          {...form.getInputProps('subject')}
        />
        {!isFirstStep && (
          <NumberInput
            label="Number of days to wait before sending this follow up"
            {...form.getInputProps('wait_days')}
            mb="md"
            min={1}
          />
        )}
        <Text mb="sm" fz="sm">
          Variables available for you to use in the templates:{' '}
          {`{{ name }}, {{ address }}, {{ offerPrice }}, {{ firstName }}`}
        </Text>

        <StyledEditor
          onUpdate={setContent}
          initialContent={workflowStep?.content}
          buttons={() => (
            <Flex w="100%" justify="flex-end">
              <Button
                type="submit"
                mt="md"
                loading={isCreating || isUpdateLoading}
              >
                {workflowStep ? 'Update' : 'Create'}
              </Button>
            </Flex>
          )}
        />

        {workflowStep && (
          <WorkflowStepAttachmentForm workflowStep={workflowStep} />
        )}
      </form>
    </div>
  );
}
