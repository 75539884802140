import React from 'react';
import { InboxActivity } from 'fe/queries';
import { Badge, Flex, Text } from '@mantine/core';
import classes from './ConversationMessage.module.scss';
import { ProfilePicture } from 'fe/ui/shared';
import { formatStatusToLabel } from 'shared/formatter';
import { AttachmentCard } from './../AttachmentCard';

interface ConversationMessageProps {
  item: InboxActivity;
}

export function ConvoItem({ item }: ConversationMessageProps) {
  let color = '';
  let beforeName = '';
  switch (item.type) {
    case 'message':
      color = 'transparent';
      beforeName = 'From';
      break;
    case 'note':
      color = 'yellow.0';
      beforeName = 'Note by';
      break;
    default:
      color = 'gray.1';
      beforeName = 'Lead status updated by';
  }

  return (
    <Flex
      className={`${classes.bubble} ${classes.note}`}
      style={{ flex: 1 }}
      direction={item.type === 'status_change' ? 'row' : 'column'}
      justify={item.type === 'status_change' ? 'space-between' : 'flex-start'}
      align={item.type === 'status_change' ? 'center' : 'flex-start'}
      gap="xs"
      w="100%"
      bg={color}
    >
      {item.user_name && (
        <ProfilePicture
          name={item.user_name}
          picture={item.user_picture}
          beforeName={beforeName}
          extraContent={
            <Text fz="xs" c="dimmed">
              {item.created_at}
            </Text>
          }
        />
      )}
      {item.type === 'status_change' ? (
        <Flex align="center" gap="xs" data-testid="label">
          <Badge
            variant="dot"
            radius="sm"
            color="dark"
            data-testid="value"
            size="lg"
          >
            {formatStatusToLabel(item.content)}
          </Badge>
        </Flex>
      ) : (
        <Text
          mt={item.type === 'note' ? 'sm' : undefined}
          dangerouslySetInnerHTML={{ __html: item.content }}
        />
      )}

      {item.type === 'message' && item.attachments && (
        <Flex gap="sm" wrap="wrap">
          {item.attachments?.map((attachment) => (
            <AttachmentCard attachment={attachment} key={attachment.id} />
          ))}
        </Flex>
      )}
    </Flex>
  );
}
